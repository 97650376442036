<template>
  <div>
    <v-app id="inspire">
      <section
        id="welcome"
        class="hide-overflow"
      >
        <v-layout>
          <v-flex
            hidden-sm-and-down
            md6
          >
            <v-img
              :src="require('@/assets/welcome.png')"
              height="100vh"
            />
          </v-flex>
          <v-flex
            xs12
            md6
            align-content-space-between
            layout
            :pa-5="$vuetify.breakpoint.smAndDown"
            wrap
          >
            <v-container
              fluid
              fill-height
            >
              <v-layout
                align-center
                justify-center
              >
                <v-flex
                  xs12
                  sm8
                >
                  <msg
                    v-if="message.state"
                    :msg-data="message"
                  />
                  <v-card class="elevation-12">
                    <v-card min-height="500px">
                      <v-card-title
                        style=" background: #003853; color: white"
                        class="justify-center dark"
                      >
                        Login
                      </v-card-title>
                      <v-card-text>
                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12>
                                <v-text-field
                                  v-model="username"
                                  label="Username/Email*"
                                  required
                                />
                              </v-flex>
                              <v-flex xs12>
                                <v-text-field
                                  v-model="password"
                                  label="Password*"
                                  type="password"
                                  required
                                />
                              </v-flex>
                            </v-layout>
                          </v-container>
                          <small>*indicates required field</small>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="primary"
                            @click="close"
                          >
                            Volver
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="login"
                          >
                            Ingresar
                          </v-btn>
                        </v-card-actions>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
    </v-app>
  </div>
</template>

<script>

import msg from '../components/message/msgComp'

export default {
	components: {
		msg
	},
	data () {
		return {
			message: { state: false, msg: '', type: 0 },
			dialog: false,
			username: '',
			password: ''

		}
	},
	computed: {
		user () {
			return this.$store.state.user
		}
	},
	created: function () {
	},
	methods: {
		/*  login: function (){
            this.$router.push("/user")
        },  */
		close: function () {
			this.$router.push('/')
		},

		login: function () {
			const username = this.username
			const password = this.password
			this.$store
				.dispatch('login', { username, password })
				.then(() => this.$router.push('/user'), this.dialog = false)
				.catch(err => {
					this.message.state = true
					this.message.msg = err.response.data.response
					this.message.type = 0
				})
		}

	}
}
</script>
