<template>
    <div>
        <v-alert :type="tipo" :value="show">{{ this.msgData.msg }}</v-alert>
    </div>
</template>

<script>

export default {
  props: {
    msgData: {}
  },
  data () {
    return {
      show: false,
      tipo: ''
    }
  },
  methods: {

  },
  created: function () {
    // console.log(this.msgData)
    this.show = true
    switch (this.msgData.type) {
      case 0:
        this.tipo = 'error'
        break
      case 1:
        this.tipo = 'success'
        break
      default:
        this.tipo = 'error'
        break
    }
  }
}
</script>
